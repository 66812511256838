import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import { styled } from "@mui/system";
import {
  Container,
  Box,
  Button,
  Grid,
  Typography,
  Stack,
  Card,
  CardHeader,
  Divider,
  CardContent,
} from "@mui/material";
import TabsUnstyled from "@mui/base/TabsUnstyled";
import TabsListUnstyled from "@mui/base/TabsListUnstyled";
import TabPanelUnstyled from "@mui/base/TabPanelUnstyled";
import { buttonUnstyledClasses } from "@mui/base/ButtonUnstyled";
import TabUnstyled, { tabUnstyledClasses } from "@mui/base/TabUnstyled";
import ContactModal from "../components/modals/Contact";
import Seo from "../components/seo";
import AboveImage from "../images/aboveimg.png";
import ServicesImage from "../images/fold.png";
import IconOne from "../images/one.inline.svg";
import IconTwo from "../images/two.inline.svg";
import IconThree from "../images/three.inline.svg";
import IconFour from "../images/four.inline.svg";
import IconFive from "../images/five.inline.svg";
import IconSix from "../images/six.inline.svg";
import Corner from "../images/corner.inline.svg";
import CornerSecond from "../images/corner_second.inline.svg";
import Map from "../components/animations/map.js";
import MapImage from "../images/map.inline.svg";
import Header from "../components/Header";

const Tab = styled(TabUnstyled)`
  color: white;
  cursor: pointer;
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 24px;
  text-transform: uppercase;
  color: #f4f7fa;
  background-color: transparent;
  width: 100%;
  padding: 20px 10px;
  border: none;
  border-radius: 5px;
  display: flex;
  justify-content: center;

  &.${tabUnstyledClasses.selected} {
    color: white;
    font-weight: bold;
    position: relative;
  }

  &.${tabUnstyledClasses.selected}:after {
    content: " ";
    height: 3px;
    width: calc(100% - 55px);
    background: #f2bc46;
    position: absolute;
    bottom: 12px;
    margin: auto;
  }

  &.${buttonUnstyledClasses.disabled} {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const TabPanel = styled(TabPanelUnstyled)`
  width: 100%;
  font-family: IBM Plex Sans, sans-serif;
  font-size: 0.875rem;
`;

const TabsList = styled(TabsListUnstyled)`
  max-width: 830px;
  background-color: #14274e;
  margin: 0 auto;
  border-radius: 8px;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: space-between;
  margin-bottom: 40px;
  padding-left: 10px;
  padding-right: 10px;
`;

export default function indexPage({ data }) {
  return (
    <Layout>
      <Seo
        title="Afrasam"
        description=""
        image="/cover.png"
      />
      <Header />
    </Layout>
  );
}

export const query = graphql`
  query {
    vc: file(relativePath: { eq: "aboveimg.png" }) {
      childImageSharp {
        fluid(maxWidth: 2000, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

  }
`;
